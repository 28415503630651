.calender {
  border-radius: 10px;
  width: 70%;
}

.dateField {
  border: 1px solid #ced4da;
  outline: none;
  background-color: #f1f1f1;
  border-radius: 10px;
  width: -webkit-fill-available;
  padding: 13px;
}

.leaveDatePicker {
  padding: 3px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-radius: 5px;
  // cursor: none;
}

.leaveDateRangePicker {
  padding: 4px;
  border-radius: 5px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  width: -webkit-fill-available;
}

.smallLeaveDatePicker {
  padding: 11px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-radius: 5px;
}

.smallLeaveDateRangePicker {
  padding: 11px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-radius: 5px;
  font-size: 18.8px;
}

.smallScreen_datePicker {
  display: flex;
  justify-content: space-between;
}

.smallScreen_datePicker_startDate {
  padding: 11px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-radius: 5px;
  width: 40vw;
}

.smallScreen_datePicker_endDate {
  padding: 11px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-radius: 5px;
  width: 40vw;
}

@media only screen and (max-width: 413px) {
  .smallScreen_datePicker {
    flex-direction: column;
    justify-content: space-between;
    height: 35vw;
  }

  .smallScreen_datePicker_startDate {
    width: auto;
  }

  .smallScreen_datePicker_endDate {
    width: auto;
  }
}

@media only screen and (max-width: 280px) {
  .smallScreen_datePicker {
    height: 45vw;
  }
}

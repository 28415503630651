.SleaveContainers {
  border: 0.3px solid #c4c4c4;
  padding: 1.15rem 1.25rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 2%;
}

.chidContainer,
.chidContainerB {
  display: flex;
  width: '100%';
  align-items: center;
  justify-content: space-between;
}

.chidContainerB {
  margin-top: 10px;
}

.reason {
  font-weight: 100;
  font-size: 14px;
  line-height: 17px;
  color: #50555c;
  padding: 5px 0px 5px 0px;
  word-break: break-all;
}

.name {
  font-weight: 700;
  text-transform: capitalize;
}

.days {
  color: #8e8e8e;
}

.leaveType {
  background-color: #eb6a25;
  color: white;
  font-size: 12px;
  padding: 1px 10px 1px 10px;
  border-radius: 5px;
  height: 19px;
  width: 74px;
  left: 55px;
  gap: 10px;
  margin-right: 70px;
  padding-bottom: 20px;
}

.IconCollection {
  display: flex;
}

.Icon {
  background-color: #d92550;
  color: white;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.successIcon {
  background-color: #3ac47d;
  color: white;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.sIcon {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

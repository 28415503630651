.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.image_fluid {
  max-width: 35px;
  height: auto;
}
.image_fluid1 {
  max-width: 32px;
  height: auto;
}
// * {
//   overflow-x: hidden !important;
// }
// Navbar and SideBar StyleSheet

.nav {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  position: fixed !important;
  width: 100%;
  z-index: 10;
}
.nav_padding {
  padding-top: 64px;
}

.nav_left {
  display: flex;
  width: 15vw;
  padding-left: 10px;
}

.toggler {
  font-size: 1.87rem;
  color: #4492cf;
  cursor: pointer;
}

.navbar_brand {
  max-width: 138px;
  width: 76%;
  object-fit: contain;
  height: 48.5px;
}

#bell_icon {
  font-size: 1.34rem;
  background: #ffffff;
}

#badge {
  border-radius: 70%;
  font-size: 13.2px;
  padding: 3.2px 4px;
  font-style: normal;
  position: relative;
  bottom: 10px;
  left: -9px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  line-height: 123.69%;
}

.nav_title {
  color: black;
  font-weight: bold;
  font-size: 0.95rem;
  letter-spacing: 0.7px;
  user-select: none;
  margin-top: 5px;
}

.nav_designation {
  font-size: 0.87rem;
  font-weight: normal;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.44);
}

.nav_avatar {
  width: 60px;
  object-fit: cover;
  height: 60px !important;
  border-radius: 50%;
}

//SideBar

.sidebar {
  height: 95vh;
  width: 5.5%;
  position: fixed;
  left: 0;
  background: #ffffff;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.open {
  width: 15.5%;
}

.close {
  width: 5.5%;
}

.close .sidebar_title {
  //display: none;
  opacity: 0;
}

.open .sidebar_title {
  //display: block;
  opacity: 1;
}

.close .sidebar_section {
  border-top: 2px solid rgba(18, 20, 46, 0.12);
}

.close .sidebar_item > i {
  font-size: 17px;
  margin: 12px 0px;
}

.close .active {
  text-align: center;
}

.close .active .sidebar_item > i::before {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.8);
}

.close .sidebar_item > i:hover::before {
  font-weight: 600 !important;
  color: #222222;
}

.close .sidebar_item > p {
  display: none;
}

.close ~ .dashboard_body {
  margin-left: 5.5%;
  width: calc(100% - 5.5%);
}

.dashboard_body {
  transition: margin-left 0.8s;
  width: calc(100% - 15.5%);
  height: auto;
  margin-left: 15.5%;
  background: rgba(186, 186, 186, 0.01);
}

.sidebar_title {
  color: #4492cf;
  font-size: 14.7px;
  font-family: 'Roboto';
  letter-spacing: 0.7px;
  font-weight: bold;
  text-transform: uppercase;
}

.sidebar_link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  transition: 0.2s;
}

.active {
  display: block;
  background: rgba(210, 238, 255, 0.7);
  color: black;
  border-radius: 5px;
  padding: 3.5px 0px 0px 0px;
  font-weight: 600;
}

.sidebar_item {
  color: #222222;
  text-align: justify;
  cursor: pointer;
}

.sidebar_item:hover {
  text-decoration: none;
  background: rgba(210, 238, 255, 0.7);
  color: #222222;
  border-radius: 5px;
  font-weight: 600;
}

.sidebar_item i {
  font-size: 14px;
  position: relative;
  left: 1px;
}
.sidebar_item p {
  font-size: 14.7px;
  position: relative;
  left: 14px;
}

//EmployeeSummaryDashborad
.outerEmployeeSumDiv {
  margin-top: 0px;
  position: relative;
  min-height: 543px;
  width: 75vw;
  margin-left: calc(50% - 40.5vw);
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.innerEmployeeSumDiv {
  position: relative;
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .innerEmployeeSumDiv {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.inner2EmployeeSumDiv {
  display: flex;
}
@media (max-width: 639px) {
  .inner2EmployeeSumDiv {
    flex-direction: column;
    align-items: stretch;
  }
}

.leftEmployeeSumDiv {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: calc(38% - 10px);
  margin-left: 0px;
}
@media (max-width: 639px) {
  .leftEmployeeSumDiv {
    width: 100%;
  }
}
.btnEmpGroup {
  position: relative;
  overflow: hidden;
  align-items: center;
  gap: 20px;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
  background: #fff;
  margin-right: 0px;
  margin-top: 6px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 50px;
}
.embuttonfont {
  position: relative;
  color: #646464;
  font-family: Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  left: -7px;
}

.empbtnnum {
  position: relative;
  color: #646464;
  font-family: Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-left: -25px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.maskgroup {
  height: 33px;
  position: relative;
  width: 32px;
  left: 4px;
  top: -3px;
}

.empheadimg {
  height: 20px;
  position: relative;
  width: 20px;
  top: -5px;
  left: 15px;
}
.barSection {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  background: var(--theme-200, #f7c3a8);
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  left: 5px;
}

//EmployeeBarGraph
.column2 {
  display: flex;
  position: absolute;
  flex-direction: column;
  line-height: normal;
  width: 90%;
  max-width: 640.417px;
  height: auto;
  margin-left: 31rem;
}

@media (max-width: 639px) {
  .column2 {
    width: 100%;
  }
}

.div31 {
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  gap: 21px;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  height: 23rem;
  margin-left: 6rem;
  width: 47rem;
}
@media (max-width: 991px) {
  .div31 {
    margin-top: 27px;
  }
}

.div32 {
  position: relative;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 21px;
  margin-top: 21px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div32 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.div33 {
  display: flex;
}
@media (max-width: 639px) {
  .div33 {
    flex-direction: column;
    align-items: stretch;
  }
}

.column3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: calc(67% - 10px);
  margin-left: 0px;
  margin-top: 49px;
}
@media (max-width: 639px) {
  .column3 {
    width: 100%;
  }
}
.div34 {
  position: relative;
  align-items: flex-start;
  gap: 21px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div34 {
    margin-top: 21px;
  }
}
.div35 {
  position: relative;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-top: -35px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.div36 {
  position: relative;
  align-items: flex-start;
  gap: 21px;
  width: 100%;
  max-width: 124px;
  margin-top: 0px;
  align-self: start;
  display: flex;
  flex-direction: column;
}
.div37 {
  position: relative;
  margin-right: 5px;
  margin-bottom: 4px;
  margin-top: 0px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.div38 {
  position: relative;
  justify-content: center;
  color: var(--icon-t-ext, #999);
  font-family: Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 1px;
  margin-right: 0px;
  margin-top: 27px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div38 {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.column4 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: calc(33% - 10px);
  margin-left: 20px;
}
@media (max-width: 639px) {
  .column4 {
    width: 100%;
  }
}
.div62 {
  position: relative;
  padding-top: 0px;
  align-items: center;
  gap: 64px;
  margin-top: -5px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div62 {
    margin-top: 21px;
  }
}
.div63 {
  position: relative;
  margin-right: 0px;
  margin-top: 15px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.div64 {
  position: relative;
  justify-content: center;
  color: var(--Dark-Text, #3d3d3d);
  font-family: Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.4px;
  width: 100%;
  max-width: 96px;
  margin-left: -13px;
  margin-top: 0px;
  align-self: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div64 {
    margin-left: 0px;
  }
}
.div65 {
  position: relative;
  align-items: flex-end;
  gap: 13px;
  margin-right: 0px;
  margin-top: 42px;
  align-self: stretch;
  display: flex;
  left: 7px;
  flex-direction: column;
}
.div66 {
  position: relative;
  margin-right: 0px;
  margin-top: -25px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.div68 {
  position: relative;
  width: 100%;
  max-width: 177px;
  margin-left: -5px;
  margin-bottom: 10px;
  margin-top: 10px;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 21px;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 991px) {
  .div68 {
    margin-left: 0px;
    justify-content: center;
  }
}

.containerbar {
  display: flex;
  align-items: center;
  gap: 0px;
}

.containeremp {
  width: 80px;
  flex-shrink: 0;
  margin-top: 20px;
}

.barsection {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  background: var(--theme-200, #f7c3a8);
  height: 27.5px;
  margin-top: 20px;
}

.EmployeeTypeName {
  position: relative;
  color: var(--Dark-Text, #3d3d3d);
  font-family: Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
}

//PieChartEmployeeSummary
.maleFemaleEmployee {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 35rem;
  margin-left: 0.7rem;
  height: 23rem;
}

.circularProgressBarEmployee {
  width: 115px;
  margin-left: 12.7rem;
  margin-top: -1.5rem;
}

.circularTotalText {
  font-size: 15px;
  top: 5.5rem;
  left: 2.5rem;
  font-weight: 700;
  position: relative;
}

.summaryIcon {
  padding: 15px;
  height: 50px;
}

.summaryTitle {
  padding: 1px 1px 25px 13px;
  font-weight: 600;
  color: #999;
  font-size: 16px;
  font-family: 'ROBOTO';
}

.genCountPercent {
  display: flex;
  justify-content: space-around;
  padding: 18px 18px;
  gap: 10rem;
  margin-left: 2rem;
  margin-top: -3rem;
}
.pieText {
  font-size: 16px;
  font-weight: 600;
  color: #868686;
}
.pieData {
  display: flex;
  justify-content: space-around;
  padding: 8px 7px;
  align-items: center;
  gap: 8rem;
  margin-right: 1rem;
  border-top: 0.2px solid var(--Dark-Text, #3d3d3d);
}

//profile dropdown of navbar
.profile_dropdown {
  width: 200px !important;
  height: 145px !important;
  top: 55px;
  left: 35px;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border: none !important;
  padding: 0px !important;
  font-size: 0.92rem;
  color: #c2c2c2;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
}
//notification dropdown
.notification_dropdown {
  width: 360px;
  top: 55px;
  left: -175px;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border: none !important;
  padding: 0px !important;
  font-size: 15px;
}
.notification_head {
  background: #4492cf;
  border-radius: 7px 7px 0px 0px;
  color: $white;
  padding: 18px 26px;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.2px;
}
.notification_body {
  background: $white;
  max-height: 296px;
  overflow-y: scroll;
  color: rgba(0, 0, 0, 0.75);
}
.notification_time {
  font-size: 11.4px;
  align-self: flex-end;
  color: black;
  font-weight: 550;
  position: relative;
  top: 7px;
  letter-spacing: -0.3px;
}
.notification_title {
  padding: 5px 9px 14px 24px;
  font-size: 14.3px;
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.4);
  p {
    max-width: 260px;
    color: #222222;
    font-style: normal;
    text-align: justify;
  }
}

.notification_mark_read {
  color: #4492cf;
  font-style: normal;
  font-size: 15.2px;
  background: $white;
  width: 100%;
  z-index: 1000 !important;
  height: 40px;
  h6 {
    margin: 5px 0px 11px 25px;
    cursor: pointer;
    display: inline;
  }
}

.margin_top {
  margin-top: 80px !important;
}

.profile_dropdown_text_color {
  color: rgba(34, 34, 34, 0.6) !important;
}

.profile_dropdown_text_color:hover {
  background-color: rgba(210, 238, 255, 0.7) !important;
}

//profile component styles
.alert_warning {
  color: #222;
  background-color: #d4913d4d;
  border: none !important;
}

.profile_upperSection {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
}

.profile_background {
  flex-basis: auto;
  background: #ffffff;
  border-radius: 7px;
  padding: 0 10px;
  display: inline-block;
}

.profile_logo {
  width: 111px;
  border-radius: 50%;
}
.profile_detail span {
  font-size: 14.3px;
  color: #8f8f8f;
}
.monthly_overview {
  font-size: 13px;
  color: #8f8f8f;
}

.heading {
  color: #4492cf;
  font-size: 16px;
  padding: 15px 25px;
  font-weight: bold;
  position: relative;
  top: 7px;
}

.job_description {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.bg {
  background: #d2eeffb2;
  font-size: 16px;
}

.viewAll {
  color: #4492cf;
  font-size: 16px;
}

.avatar {
  width: 40.97px;
  height: 40px;
  padding-top: 3px;
}
//
@media screen and (max-width: 1000px) {
  .profile_background {
    width: 100%;
  }
}
//Request for leave
.fs_26 {
  font-size: 26px;
}
.dash_line {
  width: 1px;
  height: 36px;
  border: 1px solid #979797;
}
.approve_btn {
  width: 90px;
  height: 28px;
  border-radius: 7px;
  border: 1px solid transparent;
}
.btn_approve {
  color: $white;
  background-color: #3ac47d;
}
.btn_disapprove {
  color: $white;
  background-color: $red;
}
.img_container {
  width: 35px;
  height: 35px;
}
.boxed_shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
}
.leave_reason {
  font-size: 14.2px;
}
.full_table {
  width: 100%;
}
.pages {
  width: 75%;
}
.leave_color {
  color: #4492cf;
}
@media only screen and (max-width: 321px) {
  .fs_14 {
    font-size: 10px !important;
  }
  .open {
    width: 67%;
    left: 0;
    z-index: 100 !important;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }
  .open ~ .dashboard_body {
    // height: fit-content;
    margin-left: 0 !important;
    z-index: 0 !important;
    width: 100%;
  }
  .close {
    left: 100%;
  }
  .close ~ .dashboard_body {
    width: 100%;
    margin-left: 0;
    z-index: 0 !important;
    // height: 200vh;
  }
  .nav_avatar {
    width: 12vw;
    height: 10vh;
  }
  .navbar_brand {
    max-width: 140px;
    width: 50%;
    object-fit: contain;
    height: 38px;
  }
  .sidebar_item p {
    position: relative;
    left: 8px;
  }
  .sidebar_item i {
    position: relative;
    left: 2px;
  }
  .profile_dropdown {
    top: 50px;
    left: -142px;
  }
  .profile_logo {
    width: 71px;
  }
  .profile_detail h4 {
    font-size: 14px;
  }
  .profile_detail span,
  .monthly_overview,
  .job_description {
    font-size: 12px;
  }
  .job_title,
  .contact_detail,
  .profile_background h5,
  .employee_leave_table,
  .employee_leave_table h6 {
    font-size: 12px;
  }
}

@media screen and (min-width: 319px) and (max-width: 500px) {
  .fs_14 {
    font-size: 14px !important;
  }
  .nav_avatar {
    width: 13vw;
    height: 6.75vh !important;
  }
  .navbar_brand {
    max-width: 160px;
    width: 40%;
    object-fit: contain;
    height: 35px;
  }
  .open {
    width: 61%;
    left: 0;
    z-index: 100 !important;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }
  .open ~ .dashboard_body {
    margin-left: 0 !important;
    z-index: 0 !important;
    width: 100%;
  }
  .close {
    left: 100%;
  }
  .close ~ .dashboard_body {
    width: 100%;
    margin-left: 0;
    z-index: 0 !important;
  }
  .sidebar_item p {
    position: relative;
    left: 15px;
  }
  .sidebar_item i {
    position: relative;
    left: 9px;
  }

  .profile_dropdown {
    top: 50px;
    left: -142px;
  }
  .profile_logo {
    width: 71px;
  }
  .profile_detail h4 {
    font-size: 14px;
  }
  .profile_detail span,
  .monthly_overview,
  .job_description {
    font-size: 12px;
  }
  .job_title,
  .contact_detail,
  .profile_background h5,
  .employee_leave_table,
  .employee_leave_table h6 {
    font-size: 14px;
  }
  .leave_days {
    font-size: 11px;
    padding: 4px 10px;
  }

  .bg {
    font-size: 15.4px;
  }

  .aLink {
    text-decoration: none;
  }
  .viewAll {
    font-size: 15px;
  }

  .avatar {
    height: 28.0950927734375px;
    width: 32px;
  }

  //employeeonleave ends

  .full_table {
    width: 1000px !important;
  }
  .pages {
    width: 100%;
  }
  .go_back {
    position: absolute;
    z-index: 10;
    top: 60px;
  }
}

@media screen and (min-width: 501px) and (max-width: 750px) {
  .nav_avatar {
    width: 8.1vw;
    height: 6.1vh;
  }
  .navbar_brand {
    max-width: 112px;
    width: 26%;
    object-fit: contain;
    height: 27px;
  }
  .open {
    // height: calc(100vh - 66px);
    width: 38.5%;
    left: 0;
    z-index: 100 !important;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }
  .open ~ .dashboard_body {
    margin-left: 0 !important;
    z-index: 0 !important;
    width: 100%;
  }
  .close {
    left: 100%;
  }
  .close ~ .dashboard_body {
    width: 100%;
    margin-left: 0;
    z-index: 0 !important;
  }
  .sidebar_item p {
    position: relative;
    left: 20px;
  }
  .sidebar_item i {
    position: relative;
    left: 13px;
  }
  .profile_dropdown {
    top: 50px;
    left: -142px;
  }
  .full_table {
    width: 1000px !important;
  }
}
@media screen and (min-width: 750px) and (max-width: 991px) {
  .profile_dropdown {
    top: 60px;
    left: -142px;
  }
  .full_table {
    width: 1000px !important;
  }
}

//permission form
.permission {
  width: 100%;
}

@media screen and (min-width: 750px) and (max-width: 1000px) {
  .nav_avatar {
    width: 9vw;
    height: 8vh !important;
  }
  .nav_padding {
    padding-top: 72px;
  }
  .navbar_brand {
    max-width: 98px;
    width: 23%;
    object-fit: contain;
    height: 25px;
  }
  .open {
    // height: calc(100vh - 66px);
    width: 32%;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }
  .open ~ .dashboard_body {
    margin-left: 32% !important;
    width: calc(100% - 32%);
    // height: max-content;
    z-index: 0 !important;
  }
  .close {
    text-align: center;
    width: 12%;
  }
  .close ~ .dashboard_body {
    margin-left: 12%;
    width: calc(100% - 12%);
  }
  .sidebar_item p {
    position: relative;
    left: 16px;
  }
  .sidebar_item i {
    position: relative;
    left: 10px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1220px) {
  .nav_avatar {
    width: 5.5vw;
    height: 7vh !important;
  }
  .navbar_brand {
    max-width: 170px;
    width: 91%;
    object-fit: contain;
    height: 57px;
  }

  .open {
    // height: calc(100vh - 66px);
    width: 22%;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }

  .open ~ .dashboard_body {
    margin-left: 22% !important;
    width: calc(100% - 22%);
    z-index: 0 !important;
  }

  .close {
    text-align: center;
    width: 9%;
  }
  .close ~ .dashboard_body {
    margin-left: 8%;
    width: calc(100% - 9%);
  }
  .sidebar_item p {
    position: relative;
    left: 17.8px;
  }
  .sidebar_item i {
    position: relative;
    left: 11.7px;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1420px) {
  .sidebar_item p {
    position: relative;
    left: 14px;
  }
  .sidebar_item i {
    position: relative;
    left: 1px;
  }
  .open {
    // height: calc(100vh - 66px);
    width: 17%;
    transition-duration: 0.1s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
  }
  .open ~ .dashboard_body {
    width: calc(100% - 17%);
    margin-left: 17% !important;
    z-index: 0 !important;
  }
  .close {
    text-align: center;
  }
}

// Media Queries for notification sections

@media only screen and (max-width: 300px) {
  #notification_section {
    position: absolute;
    right: 80%;
  }
  #bell_icon {
    font-size: 1.2rem;
  }
  #badge {
    font-size: 11.8px;
    padding: 2.5px 3.1px;
    position: relative;
    bottom: 35px;
    border-radius: 70%;
    left: 10px;
  }
  .notification_dropdown {
    width: 99vw;
    top: 55px;
    left: -234px;
    position: absolute;
    font-size: 14.2px;
  }
}

@media screen and (min-width: 300px) and (max-width: 340px) {
  #notification_section {
    position: absolute;
    right: 85%;
  }
  #bell_icon {
    font-size: 1.3rem;
  }
  #badge {
    font-size: 11.8px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.3px;
  }
  .notification_dropdown {
    width: 96vw;
    top: 55px;
    left: -255px;
    position: absolute;
    font-size: 14.65px;
  }
}

@media screen and (min-width: 359px) and (max-width: 370px) {
  #notification_section {
    position: absolute;
    right: 95%;
  }
  #bell_icon {
    font-size: 1.34rem;
  }
  #badge {
    font-size: 12.4px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -299px;
    position: absolute;
    font-size: 14.65px;
  }
}

@media screen and (min-width: 370px) and (max-width: 385px) {
  #notification_section {
    position: absolute;
    right: 95%;
  }
  #bell_icon {
    font-size: 1.4rem;
  }
  #badge {
    font-size: 12.4px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -313px;
    position: absolute;
    font-size: 14.95px;
  }
}

@media screen and (min-width: 386px) and (max-width: 400px) {
  #notification_section {
    position: absolute;
    right: 95%;
  }
  #bell_icon {
    font-size: 1.4rem;
  }
  #badge {
    font-size: 12.4px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -327px;
    position: absolute;
    font-size: 14.95px;
  }
}

@media screen and (min-width: 401px) and (max-width: 420px) {
  #notification_section {
    position: absolute;
    right: 95%;
  }
  #bell_icon {
    font-size: 1.4rem;
  }
  #badge {
    font-size: 12.6px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -347px;
    position: absolute;
    font-size: 14.95px;
  }
}

@media screen and (min-width: 420px) and (max-width: 430px) {
  #notification_section {
    position: absolute;
    right: 97%;
  }
  #bell_icon {
    font-size: 1.43rem;
  }
  #badge {
    font-size: 12.6px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -360px;
    position: absolute;
    font-size: 14.95px;
  }
}

@media screen and (min-width: 500px) and (max-width: 519px) {
  #notification_section {
    position: absolute;
    right: 99%;
  }
  #bell_icon {
    font-size: 1.43rem;
  }
  #badge {
    font-size: 12.6px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 95vw;
    top: 55px;
    left: -430px;
    position: absolute;
    font-size: 14.95px;
  }
}

@media screen and (min-width: 520px) and (max-width: 545px) {
  #notification_section {
    position: absolute;
    right: 98%;
  }
  #bell_icon {
    font-size: 1.45rem;
  }
  #badge {
    font-size: 12.9px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 80vw;
    top: 55px;
    left: -385px;
    position: absolute;
    font-size: 14.99px;
  }
}

@media screen and (min-width: 760px) and (max-width: 780px) {
  #notification_section {
    position: absolute;
    right: 99%;
  }
  #bell_icon {
    font-size: 1.49rem;
  }
  #badge {
    font-size: 13px;
    padding: 2.7px 3.3px;
    position: relative;
    bottom: 35px;
    left: 10.7px;
  }
  .notification_dropdown {
    width: 70vw;
    top: 55px;
    left: -550px;
    position: absolute;
    font-size: 15px;
  }
}

// Colors Variables
$primaryColor: #4492cf;
$logo-orange: #eb6a25;
$logo-blue: #4492cf;
$logo-blue-dark: #0c63a6;
$navy-blue: #23265b;
$red: #d92550;
$green: #3cba92;
$yellow: #f7b924;
$gray: #808080;
$white: #ffffff;

$form-Bg: #ecf4fa;

// Toaster-Colors
$error: #d92550;
$success: #3cba92;
$warning: #f7b924;
$info: #4492cf;

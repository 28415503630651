// Change Password and Forget Password Page StyleSheets

#lowerPart i {
  position: absolute;
  top: 14%;
  right: 4%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20.8px;
}

#remind_text {
  font-size: 16.4px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.remind_link {
  text-decoration: none;
}

// Late Punch in Modal Styles

.late_modal {
  margin-top: 8%;
}

.late_modal_form {
  background: rgba(255, 255, 255, 0.54);
  border: 2px solid #6bafe3;
  border-radius: 5px;
  font-size: 14px;
  color: #000000;
}

.late_modal_btn {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  display: inline;
}

.late_modal_btn:disabled {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
}

// Styling btn_dismiss btn in fiscal year modal

@media screen and (min-width: 280px) and (max-width: 375px) {
  .add_modal_dismiss_btn {
    padding-left: 0rem !important;
  }
  .add_modal_add_btn {
    padding-left: 0em !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .add_modal_dismiss_btn {
    padding-left: 1.5rem !important;
  }
  .add_modal_add_btn {
    padding-left: 1.5rem !important;
  }
}

// Leave Request Modal
@media screen and (max-width: 420px) {
  .tableText {
    font-size: 10px;
  }

  #modal_leave_heading {
    padding: 2px 5px;
    color: black;
    font-weight: bold;
  }

  #leave_modal_span_head {
    font-size: 14px;
    color: #4d4d4d;
    padding: 1px;
  }
}

.leave_divider {
  margin: 0 14px;
  border: 0.8px solid #bababa;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.1);
}

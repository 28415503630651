// Change Password and Forget Password Page StyleSheets

#input_group1 {
  position: relative;
}

#input_group2 {
  position: relative;
}

#form_title {
  font-size: 25.7px;
  font-weight: 400;
}

#form_control1 {
  background: rgba(196, 196, 196, 0.24);
  border-radius: 16px;
  font-size: 14.7px;
}

#form_control2 {
  background: rgba(196, 196, 196, 0.24);
  border-radius: 16px;
  font-size: 14.7px;
}

.selectDropdown {
  background: red;
  padding: 10px;
  // color: #333 !important;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  // color: #212529;
  background-color: #fff;
  // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option[disabled] {
    color: grey !important;
    font-size: 14px;
  }

  option[value=''] {
    color: red;
    font-size: 14px;
  }

  option:not([value='']) {
    color: '#3D3D3D';
    font-size: 14px;
  }
}

@media only screen and (max-width: 650px) {
  #form_title {
    font-size: 22px;
  }

  #form_control1 {
    font-size: 13.8px;
  }

  #form_control2 {
    font-size: 13.8px;
  }
}

.fiscal_modal {
  width: 28vw !important;
  height: 50vh !important;
}

.fiscal_year_add_btn {
  display: flex !important;
  justify-content: center !important;
  margin-top: 40px !important;
}

.is_current_checkbox {
  margin-left: 10px !important;
}

.is_current_checkmark {
  height: 15px;
  width: 15px;
  border-radius: 25px !important;
}

.input_description {
  height: 90px !important;
}

.input_date {
  background-color: #fff !important;
}

@media screen and (min-width: 280px) and (max-width: 500px) {
  .fiscal_modal {
    width: 90vw !important;
    height: 50vh !important;
  }
  .fiscal_year_add_btn {
    justify-content: start !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  .fiscal_modal {
    width: 100vw !important;
    height: 50vh !important;
  }
}

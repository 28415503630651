// Signup form
.card {
  background-color: white;
  border: 1px solid gray;
  z-index: 10;
  width: 80%;
  border-radius: 8px;
  margin: 2rem auto;
}

.formCard {
  padding: 1rem;
  text-align: center;
  margin: auto 60px;
  align-items: center;
}

// Desktop
@media screen and (max-width: 1024px) {
  .card {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem auto;
  }

  .formCard {
    width: 100%;
    padding: 0;
  }
}

// Leave Balance Stylesheets

.leavebalance_text {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 15px 1%;
}

.remainingLeavesSection {
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
}

.remainingLeavesSection > p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  font-size: 15.4px;
  margin: 8px auto 0 auto;
  text-align: center;
}

.leavebalance_circle {
  width: 90px;
  font-weight: bold;
}

.leavebalance_text > p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  font-size: 15.4px;
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  .leavebalance_text {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px 0px 20px;
  }
  .leavebalance_text > p {
    font-size: 14.5px;
  }
  .leavebalance_circle {
    width: 100px;
  }
}

@media screen and (min-width: 300px) and (max-width: 450px) {
  .leavebalance_text {
    flex-direction: column;
    align-items: center;
    padding: 0px 10px 0px 20px;
  }
  .leavebalance_text > p {
    font-size: 14.5px;
  }
  .leavebalance_circle {
    width: 80px;
    margin: 12px auto 6px auto;
  }
  .leaveBalance_upperSection {
    padding: 8px 10px;
    flex-direction: column;
    justify-content: center;
  }
  .remainingLeavesSection {
    width: auto;
    height: auto;
    justify-content: center;
    margin-bottom: 17px;
  }

  .remainingLeavesSection > p {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
    font-size: 15.4px;
    margin: 0 auto 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 300px) {
  .leavebalance_text {
    flex-direction: column;
  }
  .leavebalance_text > p {
    font-size: 14.5px;
  }
  .leavebalance_circle {
    width: 110px;
    margin: 10px auto;
  }
  .leaveBalance_upperSection {
    padding: 8px 10px;
    flex-direction: column;
    justify-content: center;
  }
}

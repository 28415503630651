.dashboard_calendar_section {
  padding: 10px;
  width: auto;
  height: auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.dashboard_calendar_section > img {
  max-width: 100%;
  max-height: 100%;
  width: 700px;
  object-fit: contain;
  height: auto;
  display: block;
  margin: auto auto;
}

// Dashboard Employee handbook and employee services styles

.em_handbook_div {
  margin-left: 100px;
  display: inline;
  border: 2px solid rgb(78, 78, 78);
}

.react-pdf__Page {
  display: inline;
  margin-left: 100px;
}

.document_pdf_div {
  position: relative;
}

.pdf_heading {
  font-weight: 600;
  color: #4492cf;
}

@media screen and (min-width: 274px) and (max-width: 480px) {
  .em_handbook_pdf {
    border: 1px solid rgb(20, 12, 12);
    width: fit-content;
    overflow: hidden;
    position: absolute;
    margin: auto;
    top: 25px;
  }

  .em_handbook_pdf > canvas {
    width: 300px !important;
    height: 480px !important;
  }

  .pdf_heading {
    font-size: 18px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .em_handbook_pdf {
    border: 1px solid rgb(20, 12, 12);
    width: fit-content;
    overflow: hidden;
    position: absolute;
    margin: auto;
    top: 25px;
  }

  .em_handbook_pdf > canvas {
    width: 300px !important;
    height: 480px !important;
  }
  .pdf_heading {
    font-size: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1064px) {
  .em_handbook_pdf {
    border: 1px solid rgb(20, 12, 12);
    width: fit-content;
    margin-left: 12%;
    position: absolute;
    top: 25px;
  }

  .em_handbook_docs {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-right: 0px !important;
    border: 1px solid rgb(255, 255, 255);
  }

  .pdf_heading {
    font-size: 20px;
  }
}

@media screen and (min-width: 1064px) and (max-width: 1440px) {
  .react-pdf__Page > canvas {
    width: 758px !important;
    height: 0% !important;
  }

  .em_handbook_pdf {
    border: 1px solid rgb(20, 12, 12);
    width: fit-content;
    margin-left: 23%;
    position: absolute;
    top: 25px;
    box-shadow: 1px 7px 20px -4px #888888;
  }

  .em_handbook_docs {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-right: 0px !important;
    border: 1px solid rgb(255, 255, 255);
  }

  .pdf_download_btn {
    bottom: 1vh !important;
    position: fixed;
    right: 1vw;
    z-index: 9999;
  }
}

@media screen and (min-width: 1442px) and (max-width: 1920px) {
  .react-pdf__Page canvas {
    width: 800px !important;
    height: 0% !important;
    border: 2px solid rgb(180, 40, 40) !important;
  }

  .em_handbook_pdf {
    border: 1px solid rgb(20, 12, 12);
    width: fit-content;
    margin-left: 30%;
    position: absolute;
    top: 25px;
    box-shadow: 1px 7px 20px -4px #888888;
  }
  .em_handbook_docs {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-right: 0px !important;
    border: 1px solid rgb(255, 255, 255);
  }
  .pdf_download_btn {
    bottom: 1vh !important;
    position: fixed;
    right: 1vw;
    z-index: 9999;
  }
  .pdf_heading {
    letter-spacing: 1px;
    text-align: center;
  }
}

// Test Css

.test_div {
  border: 1px solid rgb(255, 72, 72);
  // width: fit-content;
  overflow: hidden;
}

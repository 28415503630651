.pageTitle {
  color: #599dd2;
  font-weight: 600;
  font-size: 18px;
  background-color: white;
  padding-top: 1vh;
  margin-left: 4vh;
}

.pageInfoTitle {
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  color: #4492cf;
}

.mainContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.allConatiner {
  background: #ffffff;
  border: 0.7px solid #dedddd;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  gap: 21px;
}

.edit_button {
  border: 1px solid #3d3d3d;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  gap: 10px;
  width: 79px;
  height: 31px;
}

.cancel_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: 200px;
  height: 39px;

  border: 0.8px solid #000000;
  border-radius: 5px;
}

.edit_button {
  border: 1px solid #3d3d3d;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  gap: 10px;
  width: 79px;
  height: 31px;
}

.cancel_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: 200px;
  height: 39px;

  border: 0.8px solid #000000;
  border-radius: 5px;
}

.save_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: 200px;
  height: 39px;
  color: white;
  background: #4492cf;
  border: 2px solid #4492cf;
  border-radius: 5px;
}

.itemContainer_edit {
  width: 30%;
}

.itemContainer_view {
  width: 25%;
  // overflow: hidden;
  word-wrap: break-word;
}

.showData {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-width: fit-content !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  // font-size: 14px;
}

.form_contianer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact_detail_profile {
  display: flex;
  gap: 4em;
  align-items: center;
  flex-direction: row;
}

// User profile css
// .user_profile h5 {
//     color: rgba(0, 0, 0, 0.6);
// }

.user_profile p {
  min-width: 200px;
}

.user_profile_details {
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px;
  font-size: 14px;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 14px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0rem;
  }
}

.user_profile_inactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0rem;
}

.user_profile_active {
  color: #4492cf !important;
  // color: white

  background: #dff2ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px;
  border-radius: 10px;
  margin-bottom: 0rem;
  // background: #4492cf;
}

// FOR LABEL NAME

.edit_label {
  color: #2e2e2e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.inactive_label {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
  line-height: 123.69%;
}

// Leave Show Box Style
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px 20px 15px;
  //   gap: 20px;
  border-radius: 6px;
  background: #ffffff;
}

.leaveTypeBox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.leaveTypeSpan {
  font-weight: 600;
  font-size: 18px;
  line-height: 10%;
}

.editProfile {
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 500000;
    width: 6%;
  }
}

// LEAVE TABLE DESING

.ltable {
  display: flex;
  flex-direction: column;

  thead {
    // height: 150px;
    border-bottom: 1px solid #e7e7e7 !important;
    border: 1px solid #e7e7e7;
    border-radius: 7px 7px 0px 0px;
    background-color: none !important;
    width: 100%;
    tr {
      display: flex;
      width: 100%;
      padding: 0px 20px;
      background-color: #f6fbff;
      justify-content: space-between;

      th {
        padding: 10px;
        width: 25%;
        font-weight: 500;
        font-size: 16px;
        color: #646464;
        text-align: left;

        &.leaveDays,
        &.Reason,
        &.Status {
          display: none;
        }
      }
    }
  }

  tbody {
    // background: #FFFFFF;
    border-radius: 0px 0px 7px 7px;
    border: 1px solid #e7e7e7;

    tr {
      display: flex;
      width: 100%;
      padding: 0px 20px;

      background-color: #ffffff;
      justify-content: space-between;

      td {
        width: 25%;
        padding: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #646464;
        text-align: left;
        text-justify: center !important;

        &.leaveDays,
        &.Reason,
        &.Status {
          display: none;
        }
      }
      th {
        padding: 15px;
        font-weight: 500;
        line-height: 123.69%;
        font-size: 16px;
        color: #484848;
        width: 100%;
        text-align: left;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.managerLeaveReportForm {
  flex-direction: column;
}
@media screen and (min-width: 426px) {
  .ltable {
    thead {
      // height: 150px;

      tr {
        // justify-content: space-between;
        th {
          width: 15%;
          font-size: 18px;
          &.leaveDays,
          &.Reason,
          &.Status {
            display: block;
          }
        }
      }
    }

    tbody {
      // background: #FFFFFF;
      border-radius: 0px 0px 7px 7px;
      border: 1px solid #e7e7e7;

      tr {
        display: flex;
        width: 100%;
        padding: 0px 20px;

        background-color: #ffffff;
        justify-content: space-between;

        td {
          width: 15%;
          padding: 10px;
          font-weight: 500;
          font-size: 18px;
          color: #646464;
          text-align: left;
          text-justify: center !important;
          &.leaveDays,
          &.Reason,
          &.Status {
            display: block;
          }
        }

        th {
          padding: 15px;
          font-weight: 500;
          line-height: 123.69%;
          font-size: 16px;
          color: #484848;
          width: 100%;
          text-align: left;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .managerLeaveReportForm {
    flex-direction: row;
  }
}

.noBorder {
  // background-color: red;
  border: 0 !important;
  // padding-bottom: 5px !important;
}

.headColor {
  color: #000000;
}

.testContent {
  color: #666666;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  gap: 28px;

  width: 150px;
  height: 44px;

  border: none !important;
  background: #d92550 !important;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  :hover {
    cursor: pointer;
  }
}

//Admin Sidebar styleSheet

.admin_sidebar {
  width: 20%;
  background: rgba(186, 186, 186, 0.18);
  padding: 12px 10px;
  min-height: calc(100vh - 64px);
}

.admin_sidebar_link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
}

.active_admin_link {
  display: block;
  background: #e5e5e5;
  color: #4492cf !important;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
}

.admin_sidebar_item {
  text-align: justify;
  font-size: 14.5px;
  letter-spacing: 0.38px;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  padding: 5px 0px 0 6.5px;
}

.admin_sidebar_item:hover {
  background: #e5e5e5;
  color: #4492cf;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
}

.admin_body {
  padding: 0px 26px;
  width: 100%;
}

@media screen and (min-width: 280px) and (max-width: 876px) {
  #admin_fullBody {
    height: auto;
    flex-direction: column;
  }
  .admin_sidebar {
    width: 99.8%;
    background: rgba(186, 186, 186, 0.08);
    padding: 12px 0px;
  }
  .admin_sidebar_section {
    border-bottom: 0.3px solid #c4c4c4;
  }
  .admin_sidebar_item {
    font-weight: 550;
    color: black;
    padding: none;
    margin: auto 25px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
  }
  .active_admin_link {
    display: unset;
    background: unset;
    color: unset;
    border-radius: unset;
    text-decoration: none;
  }
  .admin_body {
    padding: 0px 10px;
  }
  .admin_upperSection {
    padding: 10px 0px 0 0px;
  }
  .admin_innerSection {
    flex-direction: column !important;
    height: auto;
  }
  .admin_innerSection p {
    font-size: 15.8px !important;
    text-align: center;
    color: #4492cf;
    letter-spacing: 0.3px;
  }
  .admin_upperSection span {
    font-size: 14px !important;
  }
  #admin_add_btn {
    top: 12px;
    padding: 14px 19px;
    width: unset !important;
    font-size: 15.3px !important;
  }

  #admin_view_btn {
    background-color: #4492cf !important;
    border-color: #4492cf !important;
    top: 12px;
    padding: 14px 19px;
    width: unset !important;
    font-size: 15.3px !important;
  }
  // .pr_7 {
  //   right: 13% !important;
  // }
  .name_title {
    font-size: 14.2px !important;
    color: rgba(0, 0, 0, 0.8);
  }
  .divider_line {
    margin: 0 8px;
    border: 1.5px solid #000000;
  }
  .admin_table span {
    display: none;
  }
  .admin_userList {
    padding: 13px 5px !important;
    table {
      border-radius: 5px;
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  .admin_table > table {
    border-radius: 5px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .back_to_admin {
    color: #4492cf;
    font-size: 14px;
    cursor: pointer;
    display: inline;
  }

  .userList_upperSection {
    margin: 12px 0px;
    display: unset;
  }

  .user_list > p {
    text-align: center;
  }

  .user_list {
    span {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px !important;
      position: relative;
      top: -2px;
    }
  }

  .admin_mobile_sort {
    color: rgba(0, 0, 0, 0.6);
    float: right;
    font-size: 14px;
    cursor: pointer;
  }

  // #sort_button_menu {
  //   min-width: 175px !important;
  //   background: #ffffff;
  //   border: none;
  //   outline: none;
  //   border-radius: 7px;
  // }
}

// Admin Title Bar StyleSheet

.admin_upperSection {
  padding: 26px 0px 0 0px;
  height: auto;
}

.admin_innerSection {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.admin_upperSection p {
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.3px;
  color: #4492cf;
  text-transform: capitalize;
}

.admin_upperSection span {
  font-size: 14.6px;
  font-weight: 450;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.1px;
  text-transform: none;
}

#admin_add_btn {
  background: #3ac47d;
  border-radius: 5px;
  width: 180px;
  color: #ffffff;
  font-size: 14.3px;
  position: relative;
  bottom: 10px;
  white-space: nowrap;
}

#admin_add_btn:focus,
#admin_add_btn:active:focus,
#admin_add_btn.active:focus {
  outline: none !important;
  box-shadow: none !important;
}

// Admin Empty Box Stylesheet

.admin_empty_box {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.admin_empty_box h6 {
  font-size: 16.9px;
  font-style: normal;
  color: #000000;
  font-weight: 600;
}

.admin_empty_box span {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15.2px;
  position: relative;
  top: 7px;
  text-transform: capitalize;
}

// Admin table

// .pr_7 {
//   position: relative;
//   right: 6%;
// }
.admin_table {
  background: $white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 45px;
  overflow-x: auto;
}

.admin_table_hover:hover {
  background: rgba(68, 146, 207, 0.08) !important;
}
.user_Table {
  background: $white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 45px;
  overflow-x: auto;
}
.user_Table tr {
  cursor: default;
}

.name_title {
  font-size: 14.5px;
  color: black;
  font-weight: 550;
  width: 96%;
}
.name_title_user {
  font-size: 14.5px;
  color: black;
  cursor: pointer;
}
.name_title_user:hover {
  color: #4492cf;
}

// .name_title:hover {
//   text-decoration: underline !important;
// }
.divider_line {
  border: 0.3px solid #000000;
  margin: 0 16px;
}
.action_color {
  color: rgba(0, 0, 0, 0.5) !important;
  cursor: pointer;
}

.projectAction {
  color: #007bff !important;
}
.projectAction:hover {
  text-decoration: underline;
}

.action_color > .editAction:hover {
  color: #4492cf !important;
  text-decoration: underline;
  cursor: pointer;
}

.delAction:hover {
  color: #d92550 !important;
  text-decoration: underline;
  cursor: pointer;
}

.admin_table span {
  font-size: 14.2px;
}
.btn_admin {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;
  bottom: 17.5px;
}
.btn_admin:disabled {
  font-size: 1rem !important;
}
.admin_modal {
  font-style: normal;
  width: -webkit-fill-available;
  label {
    font-size: 14.25px;
    color: rgba(0, 0, 0, 0.65);
  }
  input,
  textarea {
    font-size: 15.1px;
    color: #000000;
    letter-spacing: 0.3px;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  width: -webkit-fill-available !important;
}

//css for viewalluserlist

.admin_userList {
  padding: 15px 12px;
  table {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    background: #ffffff;
    overflow-x: scroll;
  }
}

.back_to_admin {
  color: #4492cf;
  font-size: 14.6px;
  cursor: pointer;
}

.userList_upperSection {
  margin: 30px 0px;
  justify-content: space-between;
  height: auto;
  align-items: center;
}

.user_list {
  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14.1px;
    position: relative;
    top: -2px;
  }
}

#sort_button_menu {
  min-width: 175px !important;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 7px;
}

#sort_button_menu > div {
  border: 1px solid #c4c4c4;
  text-align: center;
  padding: 8px 0px 0px 0px;
}

#sort_button_menu > div > p {
  cursor: pointer;
  text-align: center;
  color: #000000;
  font-weight: 550;
  font-style: normal;
}

#sort_button_menu > div:hover {
  background: #4492cf;
}

#sort_button_menu > div > p:hover {
  color: white;
}

// Admin Panel User Document Stylesheets
.document_switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.document_switch > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.document_switch > .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.document_switch > .slider:before {
  position: absolute;
  content: '';
  height: 17px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.document_switch > input:checked + .slider {
  background-color: #2196f3;
}

.document_switch > input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.document_switch > input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.document_switch > .slider.round {
  border-radius: 34px;
}

.document_switch > .slider.round:before {
  border-radius: 50%;
}

//warning-logo
.warning_symbol {
  color: #f7b924;
  font-size: 44px;
}

//WorkingShift Add modal  Start

.shiftTime {
  display: flex;
  justify-content: space-between;
}
.time {
  letter-spacing: 5px;
}
.shiftName {
  text-transform: capitalize;
}
//WorkingShift Add modal end
// Resusable Modals
.responsiveModal {
  // width: 80%;
  // max-width: 25% !important;
  width: 400px !important;
  margin-top: 50px !important;
}

@media screen and (max-width: 411px) {
  .responsiveModal {
    width: 95% !important;
  }
}

.activate {
  position: relative;
  bottom: 10px;
  left: 50%;
}

#activateUser {
  position: relative;
  left: 160%;
}

.activeEmployee {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  left: 150%;
}
.activeEmployeee {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  display: flex;
  margin-left: 84.5%;
}

.sIcone {
  display: flex;
  height: 100%;
  margin-left: 98%;
  font-size: 25px;
  cursor: pointer;
}

.error {
  color: red;
}

#alert {
  text-align: left;
}

.errorDateField {
  border: 1px solid red;
}

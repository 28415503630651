#input {
  border-radius: 10px;
  padding: 1.6rem;
  background-color: #f1f1f1;
  // border: none;
  display: inline-block;
  background-image: none;
  font-size: 14.7px;
}

.required {
  color: red;
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 118px;
}

#numberInput::-webkit-outer-spin-button,
#numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#punchInHistoryHeading {
  margin-top: 10px;
  color: #4492cf;
  font-weight: bold;
  font-style: normal;

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-size: 16px;
}

@media screen and (min-width: 426px) {
  #punchInHistoryHeading {
    margin-top: 0px;
    // text-transform: uppercase;
    font-size: 18px;
  }
}

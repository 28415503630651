.upperPart img {
  width: 100%;
  max-width: 280px;
  height: 86px;
  object-fit: contain;
}

#forget_password_link {
  color: $primaryColor;
  cursor: pointer;
}

#lowerPart button {
  border-radius: 16px;
  background: $primaryColor;
  color: white;
  text-decoration: none;
}

.row {
  // --bs-gutter-x: 1.5rem;
  // --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  // margin-top: calc(var(--bs-gutter-y) * -1);
  // margin-right: calc(var(--bs-gutter-x) * -.5);
  // margin-left: calc(var(--bs-gutter-x) * -.5);
}

@media only screen and (max-width: 650px) {
  .upperPart img {
    max-width: 210px;
    height: 75px;
  }
}

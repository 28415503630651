@media screen and (orientation: landscape) and (min-width: 700px) and (max-width: 850px) {
  .home_upperSection {
    height: 50vh;
    overflow-x: hidden;
    left: 20%;
  }
}

@media screen and (orientation: landscape) and (min-width: 580px) and (max-width: 700px) {
  .home_upperSection {
    height: 59vh;
    overflow-x: hidden;
  }
}

.profile_body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  height: auto;
  margin-top: 22px;
  padding: 20px;
}

.gender_option {
  display: flex;
  align-items: center;
  color: #ff0000;
}

.gender_icon {
  width: 10px;
  /* Adjust the width as needed */
  height: 10px;
  /* Adjust the height as needed */
  margin-right: 5px;
  /* Adjust the margin as needed */
}

// .profilePic_section {
//   background: #ffffff;
//   box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
//   border-radius: 7px;
//   padding: 10px 0;
//   height: auto;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   font-style: normal;
//   width: 100%;
//   margin-bottom: 30px;
//   h5 {
//     color: #4492cf;
//     font-weight: 500;
//     text-align: center;
//     margin-bottom: 10px;
//     font-size: 16px;
//   }
//   img {
//     width: 100px;
//     height: 100px;
//     border-radius: 50%;
//     object-fit: contain;
//     overflow: hidden;
//   }
// }

// .personalInfo_section {
//   background: #ffffff;
//   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
//   border-radius: 7px;
//   padding: 17px 0;
//   height: auto;
//   margin-top: 14px;
//   width: 100%;
//   h5 {
//     color: #4492cf;
//     font-weight: 550;
//     text-align: center;
//     margin-bottom: 10px;
//     font-size: 18px;
//   }
//   .form_section {
//     width: 100%;
//     height: auto;
//     form {
//       margin-top: 26px;
//       #form_row {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         label {
//           font-size: 15.7px;
//           color: rgba(0, 0, 0, 0.7);
//           font-style: normal;
//         }
//         select,
//         input[type='date'],
//         input[type='text'] {
//           font-size: 15.4px;
//         }
//       }
//     }
//   }
// }

.update_profile_main {
  display: flex;
}

.update_profile_btn_outline {
  color: #4492cf !important;
  background: #ffffff;
  border-color: #4492cf !important;
}

.update_profile_button {
  width: 121px;
  height: 40px;
}

.add_bank {
  background-color: #3ac47d !important;
  color: white !important;
  width: 175px;
  height: 45px;
  border: 1px solid transparent;
}

.profile_body label {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.profile_body input {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}

.profile_body select {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}

.profile_body select option:disabled {
  font-size: 16px;
  opacity: 0.75;
  color: #c2c2c2;
}

.profile_body input::placeholder {
  font-size: 16px;
  color: #c2c2c2;
}

.upload_docs label {
  font-size: 16px;
}

.w_75 {
  width: 75%;
}

@media screen and (min-width: 280px) and (max-width: 876px) {
  .w_90 {
    width: 90% !important;
  }
}

// Update Profile Stylesheets

.updateProfile {
  background: rgba(186, 186, 186, 0.01);
  height: auto;
  width: 100%;
  overflow-x: hidden !important;
}

.updateProfile_header {
  height: 50px;
  width: 100%;
  overflow-x: hidden !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);

  img {
    width: 95%;
    max-width: 170px;
    object-fit: contain;
    height: 35px;
  }
}

.updateProfile_upperPart {
  width: 80%;
  margin: 15px auto 40px auto;
}

// .submit_btn {
//   position: relative;
//   float: right;
//   margin-top: 4%;
//   width: 114px;
//   font-size: 15px !important;
//   color: #ffffff !important;
//   font-style: normal;
//   padding: 4px 0px;
//   background-color: #4492cf !important;
//   border-radius: 5px;
//   border: 2px solid #4492cf;
//   letter-spacing: 0.8px;
//   margin-right: 87px;
// }

// .prev_btn {
//   position: relative;
//   margin-top: 4%;
//   margin-right: 17px;
//   width: 114px;
//   font-size: 15px !important;
//   color: #4492cf !important;
//   font-style: normal;
//   padding: 4px 0px;
//   background-color: #ffffff !important;
//   border: 2px solid #4492cf !important;
//   border-radius: 5px;
//   letter-spacing: 0.8px;
// }

.profile_text_color {
  color: #4492cf !important;
}

// revamp profile css

.profilePic_section {
  padding: 4px 0;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-style: normal;
  width: 100%;
  margin-bottom: 30px;

  h5 {
    color: #494a4c;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: contain;
    overflow: hidden;
  }
}

.profilePic_container {
  width: 120px; /* Adjust the width to your desired size */
  height: 120px; /* Adjust the height to your desired size */
  overflow: hidden;
  border-radius: 50%; /* To create a circular shape */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.submit_btn {
  position: relative;
  float: right;
  margin-top: 15px;
  width: 114px;
  font-size: 15px !important;
  color: #ffffff !important;
  font-style: normal;
  padding: 4px 0px;
  background-color: #4492cf !important;
  border-radius: 5px;
  border: 2px solid #4492cf;
  letter-spacing: 0.8px;
  //margin-right: 87px;
}

.submit_btn_final {
  position: relative;
  float: right;
  margin-top: 15px;
  width: 114px;
  font-size: 15px !important;
  color: #ffffff !important;
  font-style: normal;
  padding: 4px 0px;
  background-color: #3ac47dd9 !important;
  border-radius: 5px;
  border: 2px solid #3ac47dd9;
  letter-spacing: 0.8px;
  //margin-right: 87px;
}

.personalInfo_section {
  background: #ffffff;
  box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.04), 4px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  // padding: 17px 0;
  // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  height: auto;
  margin-top: 14px;
  width: 100%;
  padding: 30px 90px 50px 90px;
  // background: #ffffff;
  // border-radius: 7px;
  // padding: 17px 0;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  // height: auto;
  // margin-top: 14px;
  // width: 100%;

  h5 {
    color: #494a4c;
    font-weight: 550;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .form_section {
    width: 100%;
    height: auto;

    form {
      margin-top: 26px;

      #form_row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        label {
          font-size: 15.7px;
          color: rgba(0, 0, 0, 0.7);
          font-style: normal;
        }

        select {
          option:not(:first-of-type) {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
          }
          option[disabled] {
            color: grey !important;
            font-size: 14px;
          }
          option:not([value='']) {
            color: rgba(0, 0, 0, 0.8);
            font-size: 15px;
          }
        }

        select::placeholder {
          color: rgba(0, 0, 0, 0.5) !important;
          font-size: 14px;
        }

        select {
          ::placeholder {
            color: rgba(0, 0, 0, 0.5) !important;
            font-size: 14px;
          }
        }
        select,
        input[type='date'],
        input[type='text'] {
          color: rgba(0, 0, 0, 0.8);
          font-size: 15px;
        }
        select::placeholder,
        input[type='date'],
        input[type='text']::placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
        }
      }
    }
  }
}

.form_bg_container {
  background: $form-Bg;
  border: 0.5px solid #8c8c8c;
  box-shadow: 4px 4px 20px rgba(207, 222, 234, 0.06), -4px -4px 20px rgba(207, 222, 234, 0.06);
  border-radius: 7px;
  // margin: 30px 90px 100px 90px;
}

.form_bg_address {
  background: $form-Bg;
  border: 0.5px solid #8c8c8c;
  box-shadow: 4px 4px 20px rgba(207, 222, 234, 0.06), -4px -4px 20px rgba(207, 222, 234, 0.06);
  border-radius: 7px;
  padding: 30px 10px;
  // margin: 30px 90px 60px 90px;
}

.form_bg_doc {
  background: $form-Bg;
  border: 0.5px solid #8c8c8c;
  box-shadow: 4px 4px 20px rgba(207, 222, 234, 0.06), -4px -4px 20px rgba(207, 222, 234, 0.06);
  border-radius: 7px;
  padding: 30px 10px;
  margin: 30px 90px 60px 90px;
}

.form_gutter {
  padding: 50px;
}

.form_section_title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px !important;
  color: #4492cf !important;
  padding-top: 30px;
}

.formCustomCheckbox input[type='checkbox'] {
  display: none !important;
}

.formCustomCheckbox {
  display: inline-block;
  padding: 10px 20px;
  // background-color: #f5f5f5;
  // border: 1px solid #ccc;
  cursor: pointer;
  font-size: 18px;
}

.checkboxBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 10px 10px;
  gap: 10px;
  font-size: 14px;

  color: white;
  background: #4492cf;
  border-radius: 5px;
  cursor: pointer;
}

.formCustomCheckbox input[type='checkbox']:checked + label > .checkboxBox {
  background: #e0e0e0;
  border-color: #999;
}

.address_title {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: 20px !important;
  // color: black !important;
  color: rgba(0, 0, 0, 0.7) !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.svg_icon {
  position: absolute;
}

//override
// .form-select{
//     padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
//     color: #BABABA !important;
// }

//official information

.group_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 90px;
}

.group_btn_offical {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 0px 90px;
}

.prev_btn {
  position: relative;
  margin-top: 15px;
  margin-right: 17px;
  width: 114px;
  font-size: 15px !important;
  color: #4492cf !important;
  font-style: normal;
  padding: 4px 0px;
  background-color: #ffffff !important;
  border: 2px solid #4492cf !important;
  border-radius: 5px;
  letter-spacing: 0.8px;
}

.form_bg_container_offical_info {
  background: rgba(68, 146, 207, 0.1);
  border: 0.5px solid #8c8c8c;
  box-shadow: 4px 4px 20px rgba(207, 222, 234, 0.06), -4px -4px 20px rgba(207, 222, 234, 0.06);
  border-radius: 7px;
  // margin: 30px 90px 10px 90px;
}

.required_field_text {
  color: red;
  position: absolute;
  width: 100%;
  left: 503%;
  top: -5%;
  font-size: smaller;
}

.employeeB {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 0px;
  overflow-x: auto;
  white-space: nowrap;
}

.thead {
  background-color: #d2eeff;
}

.leaveBtn {
  background: #4492cf;
  border: 0.5px solid #4492cf;
  box-sizing: border-box;
  border-radius: 7px;
  color: #fff;
  padding: 7px 25px;
  text-decoration: none;
}

.leaveBtn:hover {
  text-decoration: none;
  color: #fff;
}

.overflow {
  margin: 0px 35px;
  overflow-x: auto;
}

.ltable thead {
  background-color: #fff;
  vertical-align: baseline;
  border-bottom: none;
}
.ltable thead th {
  border-bottom: none;
}

.ltable th,
.ltable td {
  font-size: 15.6px;
  white-space: nowrap;
}

.ltable td {
  padding: 5px 0;
  font-size: 14.6px;
  margin: 0px;
}

.empOnLeaveReport {
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.punchHistoryTooltip {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: bold;
  text-align: center !important;
}

.punchHistoryOverlay {
  text-align: center !important;
}

.ltable th:nth-child(7) {
  text-align: center;
}

.ltable td:nth-child(7) {
  color: #fff;
  display: flex;
  justify-content: center;
}
.ltable p {
  margin: 0;
  padding: 3px 0px;
  border-radius: 7px;
  // width: 108px;
}

.pagination li {
  margin: 9px;
}

.pagination p {
  background: #ffffff;
  border: 0.5px solid rgba(68, 146, 207, 0.66);
  border-radius: 2px;
  padding: 9px 15px;
}

.pagination p.active {
  background: #4492cf;
  border: 0.5px solid #4492cf;
  color: #fff;
  border-radius: 2px;
}

.leaveicon {
  font-weight: 500;
  padding-right: 5px;
}

// leave Report

@media only screen and (max-width: 1200px) {
  .scrollIcon {
    float: right;
    right: 10px;
    color: #4492cf;
    display: block;
    font-size: 1.8rem;
  }
  .scrollIcon i {
    -webkit-text-stroke: 3px #fff;
  }
}
@media only screen and (max-width: 1200px) {
  .ltable {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 1200px) {
  .employeeB {
    margin-left: 3px;
    overflow-x: auto;
  }
  .table_emp th {
    padding-left: 8px;
  }
}

// PunchHistory Table
.punchInHistoryTable {
  overflow-x: auto;
  white-space: nowrap;
}

// Employees on leave table
.empLeaveDesignation {
  font-size: 12px;
  font-weight: bold;
  color: #939393;
  position: relative;
  bottom: 10px;
}

.table_emp {
  font-size: 16px;
}

.table_emp thead tr th {
  padding: 10px;
}

.empOnLeaveReason {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.table_emp th:nth-child(1),
.table_emp td:nth-child(1) {
  text-align: center;
}

.userTable_emp th:nth-child(1),
.userTable_emp td:nth-child(1),
.userTable_emp th:nth-child(3),
.userTable_emp td:nth-child(3),
.userTable_emp th:nth-child(4),
.userTable_emp td:nth-child(4),
.userTable_emp th:nth-child(5),
.userTable_emp td:nth-child(5) {
  text-align: center;
}

.employeeOnLeaveHeading {
  color: #4492cf;
  padding: 15px 52px;
  font-weight: bolder;
  margin-top: 5px;
}

.employeeOnLeaveFilterDate {
  display: flex;
}

.employeeOnLeaveDatePicker {
  margin-top: 25px;
}

.employeeOnLeaveSearchButton {
  background: #3ac47d !important;
  color: white !important;
  margin-top: 27px;
  margin-left: 10px;
  font-size: 19px !important;
}

.sendEmailButton {
  background: #3ac47d !important;
  border: none !important;
  margin-top: 15px;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .employeeOnLeaveHeaderComponents {
    display: flex;
    flex-direction: column;
    display: block;
  }

  .employeeOnLeaveFilterDate {
    display: -webkit-inline-box;
  }
}

.button {
  border: none;
  padding: 1rem;
  background-color: $logo-blue;
  color: white;
  border-radius: 20px;
  margin-top: 0.2rem;
  width: 30%;
  font-weight: bold;
}

#punch_btn:focus,
#punch_btn:active:focus,
#punch_btn.active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.button:hover {
  background-color: $logo-blue-dark;
}

.toggleButton1 {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  border-color: #949494 !important;
  color: #949494 !important;
  font-weight: bold !important;
  z-index: 0;
}

.toggleButton2 {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #949494 !important;
  color: #949494 !important;
  font-weight: bold !important;
  z-index: 0;
}

.activeToggleButton1 {
  background: rgb(205, 225, 240);
  border-top-color: #4492cf !important;
  border-bottom-color: #4492cf !important;
  border-left-color: #4492cf !important;
  border-right-color: #4492cf !important;
  color: #4492cf !important;
}

.activeToggleButton2 {
  background: rgb(205, 225, 240);
  border-color: #4492cf !important;
  color: #4492cf !important;
}

.suggestionList {
  position: absolute;
  top: 80px;
  width: 90%;
  min-height: 0vh;
  max-height: 60vh;
  overflow-y: scroll;
  z-index: 23;
  background-color: white;
}

.empSuggestionList {
  position: relative;
  width: 90%;
  min-height: 0vh;
  max-height: 60vh;
  overflow-y: scroll;
  z-index: 23;
  background-color: white;
}

.empSuggestionList li:hover {
  background-color: rgba(196, 196, 196, 0.3);
  cursor: pointer;
  z-index: 1000;
}

.suggestionList li:hover {
  background-color: rgba(196, 196, 196, 0.3);
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 420px) {
  .button {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .button {
    width: 100%;
  }
}

#showLeaveForm_btn {
  background-image: linear-gradient(#4492cf, #2476b7);
  outline: none !important;
  box-shadow: none !important;
  color: white;
  font-weight: bold;
  padding: 22px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  width: 98%;
}

#hideLeaveForm_btn {
  background-image: linear-gradient(#f5f8fb, #ffffff);
  box-shadow: none !important;
  color: #4492cf;
  font-weight: bold;
  padding: 22px;
  border-radius: 7px 7px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  #dayDropBtn {
    font-size: 12px;
  }
}

// punch System Stylesheets

.punchBtn_container {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  input {
    padding: 2px 10px;
  }
  #punch_btn {
    font-size: 14px;
    font-weight: 550;
    font-style: normal;
    color: #ffffff;
    border-radius: 7px;
    padding: 6px 35px;
    display: inline;
  }
  .lastPunchInTime {
    font-size: 13.5px;
    color: #757373;
    font-weight: 550;
    margin: auto 0;
    position: relative;
    bottom: 2px;
  }
  .lastPunchOutTime {
    font-size: 13.5px;
    color: #757373;
    font-weight: 550;
    margin: auto 0;
    position: relative;
    top: 2px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1137px) {
  #punch_btn {
    padding: 7px 22px !important;
  }
}

@media screen and (min-width: 751px) and (max-width: 1000px) {
  #punch_btn {
    padding: 7px 28px !important;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .lastPunchInTime {
    bottom: 55px;
    text-align: center;
  }
}

@media screen and (min-width: 500px) and (max-width: 750px) {
  #punch_btn {
    padding: 7px 28px !important;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .lastPunchInTime {
    bottom: 25px;
    text-align: center;
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  #punch_btn {
    padding: 7.5px 34px !important;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .lastPunchInTime {
    bottom: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  #punch_btn {
    padding: 7.5px 34px !important;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .lastPunchInTime {
    bottom: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 324px) and (max-width: 380px) {
  #punch_btn {
    padding: 7.5px 34px !important;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .lastPunchInTime {
    bottom: 15px;
    text-align: center;
  }
  .lastPunchOutTime {
    font-size: 13.5px;
    color: #757373;
    font-weight: 550;
    margin: auto 0;
    position: relative;
    bottom: 20px;
    top: 2px;
  }
}

@media only screen and (max-width: 324px) {
  #punch_btn {
    padding: 7.5px 34px !important;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .lastPunchInTime {
    bottom: 15px;
    text-align: center;
  }
  .lastPunchOutTime {
    font-size: 13.5px;
    color: #757373;
    font-weight: 550;
    margin: auto 0;
    position: relative;
    bottom: 20px;
    top: 2px;
  }
}

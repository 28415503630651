@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import '_variables.scss';
@import '_layout.scss';
@import '_typography.scss';
@import '_utils.scss';
@import '_forms.scss';
@import '_datePicker.scss';
@import '_landscape.scss';
@import '_dashboardProfile.scss';
@import './DashboardCalenderStyles/dashboardCalender';
@import '_admin.scss';
@import './LeaveStyles/_bulkLeaveStyles.scss';
@import './Admin/_fiscalYear.scss';
@import './LeaveStyles/sLeaveStyles.scss';
@import './EmployeeOnLeave/employeeOnLeave.scss';

* {
  @import '_variables.scss';
  // @import './LeaveStyles/sLeaveStyles.scss';
  @import '_button.scss';
  @import '_dashboardProfile.scss';
  @import '_typography.scss';
  @import '_card.scss';
  @import '_error.scss';
  @import '_modal.scss';
  @import '_section.scss';
  @import '_input.scss';
  @import '_date.scss';
  @import '_components.scss';
  @import '_table.scss';
  @import '_leaveReport.scss';
  @import '_admin.scss';
  @import '_profile.scss';
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

// .table > :not(:last-child) > :last-child > * {
//   border: none !important;
// }

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2 px 5 px;
  background-color: #d92550 !important;
  border-radius: 5 px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'] {
  padding-right: 4.125rem;
  background-image: none !important;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

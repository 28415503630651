//Styling Select Dropdown
.bulk_action_div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

// .bulk_select_dropdown {
//   border-radius: 5px;
//
//   padding-left: 15px;
//   padding-right: 15px;
// }

.goback_icon {
  margin-right: 10px !important;
}

.bulk_select_dropdown {
  color: #4492cf !important;
  width: 15%;
  margin-right: 10px;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  text-indent: 5px;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  outline: #4492cf !important;
}

@media screen and (min-width: 322px) and (max-width: 500px) {
  .bulk_select_dropdown {
    width: 100%;
  }
}

.bulk_options:nth-child(2) {
  color: black !important;
  margin: 20px !important;
}
.bulk_options:nth-child(3) {
  color: black !important;
}

.bulk_apply {
  border: 1px solid #4492cf !important;
  color: #4492cf !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.input_checkmark {
  padding: 8px !important;
}

@media screen {
}

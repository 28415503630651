// Change Password and Forget Password Page StyleSheets

#main_container {
  height: 100vh;
  overflow-x: hidden;
}

.upperPart {
  height: 50%;
  background: $primaryColor;
  padding: 5vh 0;
}

#lowerPart {
  height: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;
  position: relative;
  bottom: 24vh;
}

@media only screen and (max-width: 650px) {
  #lowerPart {
    height: 52%;
    bottom: 21vh;
  }
}

// Dashboard Home Page Styles

.dashboard_home {
  width: 100%;
  // height: 100%;
  background: rgba(186, 186, 186, 0.08);
}

.home_upperSection {
  overflow: hidden;
  height: 15vh;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
}

.home_leftSection {
  height: 100%;
  width: 77.5%;
  justify-content: space-between;
}

.home_rightSection {
  height: 100%;
  width: calc(100% - 77.5%);
  display: flex;
  align-items: center;
  padding-right: 42px;
  justify-content: center;
  // padding: 15px 22px 15px 18px;
  h6 {
    color: #4492cf;
    text-align: center;
    font-style: normal;
    font-size: 16px;
  }
}

// Punch Button Styles

// Home Page Calender Styles

@media only screen and (max-width: 324px) {
  .home_upperSection {
    height: auto;
    flex-direction: column-reverse;
  }
  .home_leftSection {
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
  }
  .home_rightSection {
    text-align: center;
    padding-right: 0px;
    margin: 12px auto 0 auto;
    display: block;
    width: auto;
    h6 {
      font-size: 15.6px;
    }
  }
  .punchBtn_container {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (min-width: 324px) and (max-width: 380px) {
  .home_upperSection {
    height: auto;
    flex-direction: column-reverse;
  }
  .home_leftSection {
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
  }
  .home_rightSection {
    padding-right: 0px;
    text-align: center;
    margin: 12px auto 0 auto;
    display: block;
    width: auto;
    h6 {
      font-size: 15.6px;
    }
  }
  .punchBtn_container {
    flex-direction: column;
    width: 100%;
  }

  #punch_btn:focus,
  #punch_btn:active:focus,
  #punch_btn.active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  .home_upperSection {
    height: auto;
    flex-direction: column-reverse;
  }
  .home_leftSection {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .home_rightSection {
    padding-right: 0px;
    text-align: center;
    display: block;
    width: auto;
    margin-top: 12px;
  }
  .punchBtn_container {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 750px) {
  .home_upperSection {
    height: auto;
    flex-direction: column-reverse;
    margin-top: 60px;
    text-align: center;
  }
  .home_leftSection {
    flex-direction: column !important;
    width: 100%;
  }
  .home_rightSection {
    padding-right: 0px;
    text-align: center;
    height: 30%;
    width: auto;
  }

  .punchBtn_container {
    flex-direction: column;
  }
}

@media screen and (min-width: 751px) and (max-width: 1000px) {
  .home_upperSection {
    height: 26vh;
    display: block;
    margin-top: 65px;
  }

  .home_rightSection {
    padding-right: 0px;
    height: 100%;
  }

  .punchBtn_container {
    flex-direction: column;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1137px) {
  .home_upperSection {
    height: 20vh;
    display: block;
  }

  .home_rightSection {
    padding-right: 0px;
    height: 100%;
  }
}

@media screen and (min-width: 1138px) and (max-width: 1230px) {
  .home_upperSection {
    height: 20vh;
    display: block;
  }

  .home_rightSection {
    height: 100%;
    padding: 10px 12px 12px 12px;
  }
}

@media screen and (min-width: 1231px) and (max-width: 1496px) {
  .home_upperSection {
    height: 16vh;
    display: block;
  }
}

// Dashboard Home Page Leave Section Styles

.home_leave_section {
  width: 100%;
  padding: 0px 0px 15px 18px;
  // height: auto;
}

.home_leave_requestForm {
  border: 0.3px solid rgba(179, 178, 178, 0.3);
  border-radius: 10px;
  background-color: white;
}

.home_leave_requestForm_smallScreen {
  border: 1px solid rgba(170, 168, 168, 0.664);
  border-top: hidden;
  border-radius: 1px 1px 10px 10px;
  background-color: white;
  margin-bottom: 30px;
}

.home_Dropdown {
  background-color: red;
  z-index: 10;
}

#hoverDropdown {
  padding: 0px;
  font-size: 14px;
  background: #ffffff;
  margin: 3px auto;
  min-width: 125px !important;
}

#hoverDropdown > button:hover {
  background: #4492cf;
  color: white;
}

.home_leave_requestForm_header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(170, 168, 168, 0.664);
}

.home_leave_requestForm_header_smallScreen {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
}

.home_leave_requestForm_heading {
  font-size: 14.8px;
  color: $primaryColor;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  padding-top: 12px;
}

.home_leave_requestForm_heading_smallScreen {
  font-size: 17px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  padding: 2px;
}

.home_leave_requestForm_body {
  display: flex;
  justify-content: space-between;
}

.home_leave_requestForm_body_smallScreen {
  display: flex;
  flex-direction: column;
}

.home_leave_requestForm_body_date {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.home_leave_requestForm_body_span {
  color: gray;
  padding: 2px;
  font-size: 13.8px;
  white-space: nowrap;
}

.home_leave_requestForm_body_span_smallScreen {
  color: gray;
  padding: 5px;
  font-size: 15.6px;
  white-space: nowrap;
}

.home_leave_requestForm_body_days {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.home_leave_requestForm_body_days_input {
  color: black;
  display: flex;
  flex-grow: initial;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  width: 100%;
  background-color: white;
  outline: none;
}

.home_leave_requestForm_body_days_input_smallScreen {
  color: black;
  display: flex;
  flex-grow: initial;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  width: 100%;
  background-color: white;
  align-content: center;
  outline: none;
}

.home_leave_requestForm_body_days_input_multipleDays {
  color: black;
  display: flex;
  flex-grow: initial;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid rgba(170, 168, 168, 0.664);
  width: 70%;
}

.home_leave_requestForm_body_LeaveType {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

#home_leave_requestForm_body_button {
  background-color: #3ac47d;
  color: $white;
  width: 40%;
  padding: 5px;
  margin: 15px 5px 15px;
}

#home_leave_requestForm_body_button_smallScreen {
  background-color: #3ac47d;
  color: $white;
  width: 25%;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 5px 15px;
}

@media only screen and (max-width: 280px) {
  #home_leave_requestForm_body_button_smallScreen {
    width: 50%;
  }
}

.leave_request_alert {
  font-size: 12.2px;
  color: gray;
  white-space: nowrap;
  display: block;
}

.leave_request_alert_smallScreen {
  font-size: 14px;
  color: gray;
  display: block;
}

.leave_request_alert_icon {
  color: #f0b734;
  padding: 2px;
  font-size: 14.8px;
  font-weight: bold;
}

.leave_request_cancelRequest {
  color: $red;
  font-size: 11px;
  cursor: pointer;
}

.leave_request_cancelRequest_smallScreen {
  color: $red;
  font-size: 14px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .home_leave_requestForm_heading {
    font-size: 12px;
  }

  .home_leave_requestForm_body {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

// Leave Request Modal StyleSheets
#Leave_modal_table {
  margin-top: 13px;
  height: auto;
  color: #4d4d4d;
}

.home_leave_balance {
  height: 100% !important;
  padding: 0px 12px 0px 12px;
}

.leave_balance_section {
  background: #ffffff;
  border: 0.3px solid rgba(179, 178, 178, 0.3);
  border-radius: 6px;
  height: 100%;
  padding: 0px 20px 15px 20px;
}

.leave_balance_section h6 {
  color: #4492cf;
  font-size: 14.8px;
  letter-spacing: 0.5px;
  font-weight: 600;
  line-height: 123.69%;
  margin-left: 14px;
}

.leave_balance_info {
  font-size: 13.2px;
  color: #7b7b7b;
  margin-left: 14px;
}

.leave_balance_counts {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 0px;
  height: 74%;
  justify-content: space-between;
  padding: 20px 0px;
  align-content: space-between;
}

.leave_balance_progress {
  width: 25.4%;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
}

.leave_balance_numbers p {
  font-size: 15.5px;
  font-weight: 550;
  color: rgb(40, 40, 40);
  position: relative;
  top: 7px;
}

.leave_balance_circles {
  width: 45px;
}

.leave_balance_numbers span {
  font-size: 14px;
  font-weight: 450;
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .leave_balance_numbers p {
    font-size: 15.6px;
  }
  .leave_balance_numbers span {
    display: none;
  }
  .home_leave_section {
    height: auto;
    padding: 0px 0px 5px 12px;
  }
  .leave_balance_progress {
    width: 30%;
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }
  .leave_balance_counts {
    gap: 18px 0px;
  }
  .leave_balance_circles {
    width: 48px;
  }
}

@media screen and (min-width: 390px) and (max-width: 800px) {
  .leave_balance_numbers p {
    font-size: 17px;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 10px;
    color: #545454;
    font-weight: 500;
  }
  .leave_balance_numbers span {
    display: none;
  }
  .home_leave_section {
    height: auto;
    padding: 0px;
  }
  .leave_balance_progress {
    width: 30%;
    flex-direction: column-reverse;
  }
  .leave_balance_section {
    padding: 0px 10px;
  }
  .leave_balance_counts {
    gap: 6px 0px;
    justify-content: space-between;
    padding: 10px 10px;
  }
  .leave_balance_circles {
    width: 51px;
  }
}

@media screen and (min-width: 300px) and (max-width: 390px) {
  .leave_balance_numbers p {
    font-size: 15.7px;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 7px;
    color: #545454;
    font-weight: 500;
  }
  .leave_balance_numbers span {
    display: none;
  }
  .home_leave_section {
    height: auto;
    padding: 0px;
  }
  .leave_balance_section {
    padding: 0px 10px;
  }
  .leave_balance_progress {
    width: 30%;
    flex-direction: column-reverse;
  }
  .leave_balance_counts {
    gap: 5px 0px;
    justify-content: space-between;
    padding: 10px 0px;
  }
  .leave_balance_circles {
    width: 46px;
    margin-left: 12px;
  }
}

@media only screen and (max-width: 300px) {
  .leave_balance_numbers p {
    font-size: 14px;
    margin-top: 6.8px;
    color: #545454;
    font-weight: 500;
  }
  .leave_balance_numbers span {
    display: none;
  }
  .home_leave_section {
    height: auto;
    padding: 0px;
  }
  .leave_balance_progress {
    width: 100%;
    flex-direction: column-reverse;
  }
  .leave_balance_section {
    padding: 0px 10px;
  }
  .leave_balance_counts {
    gap: 3px 0px;
    justify-content: space-between;
    padding: 9px 0px;
  }
  .leave_balance_circles {
    width: 44px;
  }
}

// Manager Sub ordinates List Stylesheet

.ordinates_heading {
  color: #4492cf;
  font-size: 17.3px;
  letter-spacing: 0.45px;
  margin: 8px 0px 14px 10px;
}

// .lateReasonText{
//   margin-right: 25px;
//   background-color: red;
// }
